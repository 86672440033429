import { computed } from 'vue';

export const useEcommerceGridButton = (props) => {
	const productId = computed(() => props.data.settings?.productId);
	const productVariantId = computed(() => props.data.settings?.productVariantId);

	return {
		productId,
		productVariantId,
	};
};
