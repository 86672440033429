<!-- eslint-disable max-len -->
<template>
	<!-- modal can be opened through query param, so this prevents opening it on user sites without stores and so on -->
	<ModalLayout
		v-if="isModalEnabled"
		ref="modal"
		padding="0"
		max-width="1048px"
		mobile-height="100%"
		width="100vw"
		background-color="transparent"
	>
		<div class="ecommerce-booking-modal">
			<div class="ecommerce-booking-modal__content">
				<div class="ecommerce-booking-modal__info">
					<p class="ecommerce-booking-modal__title">
						{{ title }}
					</p>
					<div class="ecommerce-booking-modal__icons">
						<div
							v-qa="'ecommerce-booking-modal-date'"
							class="ecommerce-booking-modal__text-wrapper"
						>
							<div class="ecommerce-booking-modal__icon">
								<svg
									width="16"
									height="18"
									viewBox="0 0 16 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.5 8.3335H3.83333V10.0002H5.5V8.3335ZM8.83333 8.3335H7.16667V10.0002H8.83333V8.3335ZM12.1667 8.3335H10.5V10.0002H12.1667V8.3335ZM13.8333 2.50016H13V0.833496H11.3333V2.50016H4.66667V0.833496H3V2.50016H2.16667C1.24167 2.50016 0.5 3.25016 0.5 4.16683V15.8335C0.5 16.2755 0.675595 16.6994 0.988155 17.012C1.30072 17.3246 1.72464 17.5002 2.16667 17.5002H13.8333C14.2754 17.5002 14.6993 17.3246 15.0118 17.012C15.3244 16.6994 15.5 16.2755 15.5 15.8335V4.16683C15.5 3.7248 15.3244 3.30088 15.0118 2.98832C14.6993 2.67576 14.2754 2.50016 13.8333 2.50016ZM13.8333 15.8335H2.16667V6.66683H13.8333V15.8335Z"
										fill="#474B4F"
									/>
								</svg>
							</div>
							<p class="ecommerce-booking-modal__text">
								{{ formattedSelectedDate }}
							</p>
						</div>
						<div
							v-qa="'ecommerce-booking-modal-duration-time'"
							class="ecommerce-booking-modal__text-wrapper"
						>
							<div class="ecommerce-booking-modal__icon">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9.00001 15.6665C12.6667 15.6665 15.6667 12.6665 15.6667 8.99984C15.6667 5.33317 12.6667 2.33317 9.00001 2.33317C5.33334 2.33317 2.33334 5.33317 2.33334 8.99984C2.33334 12.6665 5.33334 15.6665 9.00001 15.6665ZM9.00001 0.666504C13.5833 0.666504 17.3333 4.4165 17.3333 8.99984C17.3333 13.5832 13.5833 17.3332 9.00001 17.3332C4.41667 17.3332 0.666672 13.5832 0.666672 8.99984C0.666672 4.4165 4.41667 0.666504 9.00001 0.666504ZM13.1667 8.58317V9.83317H8.16667V4.83317H9.41667V8.58317H13.1667Z"
										fill="#474B4F"
									/>
								</svg>
							</div>
							<p class="ecommerce-booking-modal__text">
								{{ calculatedTime }} ({{ aggregatedDuration }})
							</p>
						</div>
					</div>
					<div
						v-qa="'ecommerce-booking-modal-location'"
						class="ecommerce-booking-modal__text-wrapper"
					>
						<div class="ecommerce-booking-modal__icon">
							<svg
								width="12"
								height="18"
								viewBox="0 0 12 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.00001 2.33317C8.33334 2.33317 10.1667 4.1665 10.1667 6.49984C10.1667 8.9165 7.75001 12.4998 6.00001 14.7498C4.25001 12.4998 1.83334 8.9165 1.83334 6.49984C1.83334 4.1665 3.66667 2.33317 6.00001 2.33317ZM6.00001 0.666504C2.75001 0.666504 0.166672 3.24984 0.166672 6.49984C0.166672 10.8332 6.00001 17.3332 6.00001 17.3332C6.00001 17.3332 11.8333 10.8332 11.8333 6.49984C11.8333 3.24984 9.25001 0.666504 6.00001 0.666504ZM5.41667 10.6665L9.50001 6.49984L8.33334 5.33317L5.41667 8.33317L4.08334 6.99984L2.91667 8.1665L5.41667 10.6665Z"
									fill="#474B4F"
								/>
							</svg>
						</div>
						<p class="ecommerce-booking-modal__text">
							{{ location }}
						</p>
					</div>
				</div>
				<div class="ecommerce-booking-modal__availability">
					<p class="ecommerce-booking-modal__time-title">
						{{ translations.selectDateAndTime }}
					</p>
					<div class="ecommerce-booking-modal__calendar-wrapper">
						<DatePicker
							v-model="selectedDate"
							inline
							hide-offset-dates
							auto-apply
							class="ecommerce-booking-modal-calendar"
							:class="{ 'ecommerce-booking-modal-calendar--loading': isCalendarLoading }"
							:disabled-dates="disabledDates"
							:min-date="new Date()"
							:locale="getLanguage"
							no-swipe
							:month-change-on-scroll="false"
							:enable-time-picker="false"
							:custom-props="{ language: getLanguage }"
							:month-year-component="datePickerHeader"
							@update-month-year="loadAvailability({ data: $event })"
						>
							<template #calendar-header="{ index }">
								<div>
									{{ dayNames[index] }}
								</div>
							</template>
						</DatePicker>
						<div class="ecommerce-booking-modal__time-wrapper">
							<div>
								<p class="ecommerce-booking-modal__selected-day">
									{{ selectedMonthAndWeekday }}
								</p>
							</div>
							<div class="ecommerce-booking-modal__time-slot-wrapper">
								<div
									v-if="isSlotsLoading"
									class="ecommerce-booking-modal__time-slot-loader"
								/>
								<button
									v-for="(slot, index) in timeSlots"
									v-else
									:key="`time-${index}`"
									v-qa="'ecommerce-booking-modal-time-slot'"
									class="ecommerce-booking-modal__time-slot"
									:class="{ 'ecommerce-booking-modal__time-slot--active': selectedTimeSlot === slot }"
									@click="selectTimeSlot(slot)"
								>
									{{ getVisibleDate(new Date(slot)) }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ecommerce-booking-modal__buttons">
				<button
					class="ecommerce-booking-modal__button"
					@click="closeEcommerceModal"
				>
					{{ translations.cancel }}
				</button>
				<button
					v-qa="'commerce-booking-modal-button-save'"
					class="ecommerce-booking-modal__button ecommerce-booking-modal__button--save"
					:class="{ 'loading': isCheckoutLoading }"
					:disabled="!isTimeSelected"
					@click="handleSubmit"
				>
					{{ translations.book }}
				</button>
			</div>
		</div>
	</ModalLayout>
</template>

<script>
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import DatePicker from '@vuepic/vue-datepicker';
import ModalLayout from '@zyro-inc/site-modules/components/ecommerce/modals/partials/ModalLayout.vue';
import { BOOKING_DURATION_UNIT_HOURS } from '@zyro-inc/site-modules/constants/ecommerce';
import {
	getAvailability,
	getTimeSlots,
} from '@zyro-inc/site-modules/api/StoreApi';
import { getIsInIframe } from '@zyro-inc/site-modules/utils/getIsInIframe';
import DatePickerHeader from '@zyro-inc/site-modules/components/ecommerce/date-picker/DatePickerHeader.vue';
import {
	defineComponent,
	computed,
} from 'vue';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';

export default defineComponent({
	components: {
		ModalLayout,
		DatePicker,
	},

	props: {
		translations: {
			type: Object,
			default: () => {},
		},
		language: {
			type: String,
			default: 'en',
		},
		isCartVisible: {
			type: Boolean,
			default: false,
		},
		isInPreviewMode: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const {
			isStoreTypeZyro,
			products,
			selectedBookingProduct,
			shoppingCartItems,
			setSelectedBookingProduct,
		} = useEcommerceGlobal();

		const { openEcommerceModal } = useEcommerceModal(props);
		const { initiateCheckout } = useEcommerce();
		const datePickerHeader = computed(() => DatePickerHeader);

		return {
			initiateCheckout,
			openEcommerceModal,
			datePickerHeader,
			isStoreTypeZyro,
			products,
			selectedBookingProduct,
			shoppingCartItems,
			setSelectedBookingProduct,
		};
	},

	data() {
		return {
			isTimeSelected: false,
			selectedTimeSlot: null,
			selectedDate: new Date(),
			timeSlots: [],
			isSlotsLoading: false,
			isCalendarLoading: false,
			isCheckoutLoading: false,
			disabledDates: [],
		};
	},

	computed: {
		isModalEnabled() {
			return this.isStoreTypeZyro && !!this.selectedBookingProduct;
		},
		bookingEvent() {
			return this.selectedBookingProduct?.variants[0].booking_event;
		},
		title() {
			return this.selectedBookingProduct?.title;
		},
		location() {
			return this.bookingEvent?.location;
		},
		duration() {
			return this.bookingEvent?.length || null;
		},
		durationUnit() {
			return this.bookingEvent?.length_unit;
		},
		aggregatedDuration() {
			const duration = this.durationUnit === BOOKING_DURATION_UNIT_HOURS ? this.duration / 1000 / 60 / 60 : this.duration / 1000 / 60;
			const time = this.durationUnit === BOOKING_DURATION_UNIT_HOURS ? this.translations.hourShort : this.translations.minuteShort;

			return `${duration} ${time}`;
		},
		eventStartDate() {
			if (!this.selectedTimeSlot) {
				return null;
			}

			return new Date(this.selectedTimeSlot);
		},
		eventEndDate() {
			if (!this.selectedTimeSlot) {
				return null;
			}

			const dateInMilliseconds = this.eventStartDate.getTime();
			const calculatedTime = dateInMilliseconds + this.duration;

			return new Date(calculatedTime);
		},
		calculatedTime() {
			if (!this.selectedTimeSlot) {
				return '--:--';
			}

			// TODO: this is wrong. en language can have both 12 and 24 hour formats, based on country (for example
			// us and gb). country suffix needs to be added, like en-us or en-gb
			const startTime = this.getVisibleDate(this.eventStartDate);
			const endTime = this.getVisibleDate(this.eventEndDate);

			return `${startTime} - ${endTime}`;
		},
		dayNames() {
			const week = [];
			const today = new Date();

			today.setDate((today.getDate() - today.getDay()));
			for (let i = 0; i < 7; i += 1) {
				today.setDate(today.getDate() + 1);
				week.push(new Date(today).toLocaleDateString(this.getLanguage, {
					weekday: 'short',
				}));
			}

			return week;
		},
		formattedSelectedDate() {
			const formattedStartDate = this.selectedDate?.toLocaleDateString(this.language);

			if (this.eventStartDate?.toDateString() !== this.eventEndDate?.toDateString()) {
				const formattedEndDate = this.eventEndDate.toLocaleDateString(this.language);

				return `${formattedStartDate} - ${formattedEndDate}`;
			}

			return formattedStartDate;
		},
		selectedMonthAndWeekday() {
			if (!this.selectedDate) {
				return null;
			}

			const monthDay = this.selectedDate?.toLocaleDateString(this.getLanguage, {
				month: 'long',
				day: 'numeric',
			});
			const weekday = this.selectedDate?.toLocaleDateString(this.getLanguage, {
				weekday: 'long',
			});

			return `${weekday}, ${monthDay}`;
		},
		getLanguage() {
			switch (this.language) {
			case 'br':
				return 'pt-BR';
			case 'ar':
				return 'es-AR';
			default:
				return this.language;
			}
		},
	},

	watch: {
		async selectedDate() {
			this.selectedTimeSlot = null;
			this.isTimeSelected = false;
			await this.loadTimeSlots();
		},
	},

	async mounted() {
		if (this.isModalEnabled) {
			this.setDefaultData();
		}
	},

	methods: {
		closeEcommerceModal() {
			this.setSelectedBookingProduct(null);
			this.$refs.modal.handleCloseModalAction();
		},
		async setDefaultData() {
			await this.loadAvailability();

			// get first available date from the calendar
			if (this.disabledDates.some((date) => date.toDateString() === this.selectedDate.toDateString())) {
				const remainingMonthDays = this.getAllDaysInCurrentMonth();
				const availableDates = remainingMonthDays.filter(
					(date) => !this.disabledDates.some((disabled) => disabled.toDateString() === date.toDateString()),
				).sort((a, b) => new Date(a.date) - new Date(b.date));

				this.selectedDate = availableDates.shift();
			}

			await this.loadTimeSlots();
		},
		async handleSubmit() {
			if (getIsInIframe() || this.isInPreviewMode) {
				this.openEcommerceModal('EcommerceMessageButtonDisabled');

				return;
			}

			this.isCheckoutLoading = true;
			const selectedBookingClone = {
				...this.selectedBookingProduct,
			};

			const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

			selectedBookingClone.variants[0].booking_event = {
				...selectedBookingClone.variants[0].booking_event,
				time_slot: this.selectedTimeSlot,
				time_zone: timeZone,
				length: selectedBookingClone.variants[0].booking_event.length,
				length_unit: selectedBookingClone.variants[0].booking_event.length_unit,
				date: this.selectedDate,
			};

			await this.initiateCheckout([selectedBookingClone]);
			this.isCheckoutLoading = false;
			this.closeEcommerceModal();
		},
		selectTimeSlot(time) {
			this.isTimeSelected = true;
			this.selectedTimeSlot = time;
		},
		async loadTimeSlots() {
			this.isSlotsLoading = true;

			const date = new Intl.DateTimeFormat('lt').format(this.selectedDate);

			this.timeSlots = await getTimeSlots(this.bookingEvent.id, date).then((slots) => {
				this.isSlotsLoading = false;

				return slots;
			});
		},
		async loadAvailability({ data = {} } = {}) {
			this.isCalendarLoading = true;

			const date = Object.keys(data).length ? new Date(data.year, data.month) : this.selectedDate;
			const dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);

			this.disabledDates = await getAvailability({
				bookingId: this.bookingEvent.id,
				fromDate: new Intl.DateTimeFormat('lt').format(date),
				toDate: new Intl.DateTimeFormat('lt').format(dateTo),
			}).then((response) => {
				this.isCalendarLoading = false;

				return response.map((disabledDate) => {
					const dateParts = disabledDate.split('-');

					// date-only string has to be split and passed as separate arguments to correctly return new Date()
					return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
				});
			});
		},
		getAllDaysInCurrentMonth() {
			const month = new Date().getMonth();
			const date = new Date(new Date().getFullYear(), month, new Date().getDate());

			const dates = [];

			while (date.getMonth() === month) {
				dates.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}

			return dates;
		},
		getVisibleDate(date) {
			return date.toLocaleTimeString(this.getLanguage, {
				hour: '2-digit',
				minute: '2-digit',
			});
		},
	},
});
</script>

<style lang="scss">
// extension's variable overwrite
// has to go before style import

/* stylelint-disable scss/dollar-variable-pattern */
$dp__cell_border_radius: 100px;
$dp__cell_size: 40px;
$dp__font_family: inherit;
$dp__row_margin: 0;
$dp__font_size: 14px;

/* stylelint-disable-next-line scss/at-import-partial-extension */
@import "@vuepic/vue-datepicker/dist/main.css";
@import "@zyro-inc/site-modules/scss/mixins/transitions/loading-animation";

$calendar-max-width: 360px;
$media-mobile: 760px;

.ecommerce-booking-modal {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 600px;
	overflow: hidden;
	background-color: $color-gray-light;
	border-radius: 15px;

	@media screen and (max-width: $media-mobile) {
		justify-content: space-between;
		width: 100vw;
		height: 100vh;
		max-height: unset;
		overflow: auto;
		border-radius: 0;
	}

	&__content {
		display: flex;
		height: 100%;
		overflow: hidden;

		@media screen and (max-width: $media-mobile) {
			flex-direction: column;
		}
	}

	&__info {
		display: flex;
		flex: 0 1 35%;
		flex-direction: column;
		padding: 40px 48px;
		text-align: left;
		background-color: $color-light;
		border-right: 1px solid $color-gray-border;

		@media screen and (max-width: $media-mobile) {
			position: sticky;
			flex: auto;
			padding: 24px 16px 8px;
			border-right: unset;
			border-bottom: 1px solid $color-gray-border;
		}
	}

	&__title,
	&__time-title {
		margin-bottom: 24px;
		font-size: 20px;
		line-height: 1.4;
	}

	&__title {
		@media screen and (max-width: $media-mobile) {
			display: -webkit-box;
			max-width: 90%;
			margin-bottom: 16px;
			overflow: hidden;
			font-size: 16px;
			text-overflow: ellipsis;
			white-space: normal;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}

	&__time-title {
		@media screen and (max-width: $media-mobile) {
			width: 100%;
			max-width: $calendar-max-width;
			margin: 0 auto 16px;
		}
	}

	&__icons {
		display: flex;
		flex-direction: column;

		@media screen and (max-width: $media-mobile) {
			flex-direction: row;
		}
	}

	&__text-wrapper {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		color: $color-gray;

		@media screen and (max-width: $media-mobile) {
			&:first-child {
				padding-right: 8px;
				margin-right: 8px;
				border-right: 1px solid $color-gray;
			}
		}
	}

	&__text {
		margin-left: 8px;
		font-size: 14px;
		font-weight: normal;
		line-height: 1.43;
		overflow-wrap: anywhere;
	}

	&__icon {
		width: 20px;
		height: 20px;
	}

	&__availability {
		width: 100%;
		max-width: 688px;
		padding: 40px 56px;
		padding-right: 8px;
		text-align: left;

		@media screen and (max-width: $media-mobile) {
			display: flex;
			flex-direction: column;
			width: auto;
			max-width: unset;
			padding: 24px 16px;
			overflow: auto;
		}
	}

	&__calendar-wrapper {
		display: flex;
		width: 100%;
		height: 100%;

		@media screen and (max-width: $media-mobile) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__selected-day {
		height: 100%;
		min-height: 48px;
		margin-bottom: 8px;
		font-size: 14px;
		word-break: break-word;

		@media screen and (max-width: $media-mobile) {
			min-height: unset;
		}
	}

	&__time-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-bottom: 24px;
		margin-left: 24px;

		@media screen and (max-width: $media-mobile) {
			width: 100%;
			max-width: $calendar-max-width;
			padding: 0;
			margin: 0;
		}
	}

	&__time-slot-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		height: 100%;

		// padding needed for box shadow to not be cut off
		padding: 2px;
		padding-right: 16px;
		overflow: auto;

		@media screen and (max-width: $media-mobile) {
			padding: 2px;
		}
	}

	&__time-slot-loader {
		align-self: center;
		width: 16px;
		height: 16px;
		margin-right: 16px;
		border: 2px solid $color-gray;
		border-top: 2px solid $color-gray-light;
		border-radius: 50%;
		animation: loading 0.8s linear infinite;

		@media screen and (max-width: $media-mobile) {
			margin: 16px;
		}
	}

	&__time-slot {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: 200px;
		padding: 10px 4px;
		margin-bottom: 8px;
		font-family: inherit;
		cursor: pointer;
		background-color: $color-light;
		border: 1px solid $color-gray-border;
		border-radius: 5px;
		transition: all 0.2s ease-in-out;

		&:hover,
		&:focus,
		&--active {
			background-color: $color-meteorite-light;
		}

		&:focus,
		&--active {
			color: $color-azure;
			box-shadow: 0 0 0 2px $color-azure;
		}

		@media screen and (max-width: $media-mobile) {
			max-width: none;
		}
	}

	&__buttons {
		display: flex;
		justify-content: flex-end;
		padding: 16px 24px;
		background-color: $color-light;
		border-top: 1px solid $color-gray-border;
	}

	&__button {
		padding: 8px 24px;
		font-family: inherit;
		font-size: 14px;
		line-height: 1.4;
		color: $color-dark;
		border-radius: 5px;
		transition: max-width 0.2s ease;

		@include loading-animation;

		&:not(:disabled) {
			cursor: pointer;
		}

		&--save {
			color: $color-light;
			background-color: $color-azure;

			&:disabled {
				background-color: $color-gray;
			}
		}

		&:first-child {
			margin-right: 12px;
		}
	}
}

.ecommerce-booking-modal-calendar {
	height: fit-content;

	&--loading {
		$loader-size: 48px;

		position: relative;
		filter: opacity(30%);

		&::after {
			position: absolute;
			top: 50%;
			left: calc(50% - $loader-size / 2);
			z-index: 1;
			width: $loader-size;
			height: $loader-size;
			content: "";
			border: 4px solid $color-gray;
			border-top: 4px solid $color-light;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			animation: loading 0.8s linear infinite;
		}
	}

	.dp {
		&__instance_calendar {
			padding: 20px;
		}

		&__calendar_header {
			font-weight: normal;
		}

		&__calendar_header_item {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 4px;
			text-transform: capitalize;

			@media screen and (max-width: 360px) {
				margin: 0;
			}
		}

		&__calendar_header_separator {
			display: none;
		}

		&__cell_inner {
			margin: 4px;
			font-weight: bold;

			@media screen and (max-width: 360px) {
				margin: 0;
			}
		}

		&__cell_disabled {
			color: $color-gray;
			text-decoration: line-through;
		}

		&__today {
			border: none;
		}

		&__active_date {
			color: $color-azure;
			background: rgba($color-azure, 0.1);
			border: 2px solid $color-azure;
		}

		&__date_hover:hover {
			background: rgba($color-azure, 0.1);
		}
	}

	@media screen and (max-width: $media-mobile) {
		width: 100%;
		max-width: $calendar-max-width;
		margin-bottom: 24px;
	}
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
