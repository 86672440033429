<template>
	<div
		:class="computedClass"
		:style="computedStyles"
	>
		<a
			v-for="({ link, icon, svg }) in links"
			:key="link"
			:href="link"
			target="_blank"
			rel="noopener"
			:title="`Go to ${capitalizeFirstLetter(icon)} page`"
			class="social-icons__link"
			v-on="{
				dragstart: preventLinks ? (e: Event) => e.preventDefault() : () => null,
				click: preventLinks ? (e: Event) => e.preventDefault() : () => null,
			}"
			v-html="svg"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { GridSocialIconLink } from '@zyro-inc/site-modules/types';
import { capitalizeFirstLetter } from '@zyro-inc/site-modules/utils/modifyString';

type Props = {
	links?: GridSocialIconLink[];
	preventLinks?: boolean;
	direction?: 'row' | 'column';
	directionMobile?: 'row' | 'column' | null;
	preventSpacing?: boolean;
	fullHeight?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	links: () => ([]),
	preventLinks: false,
	direction: 'row',
	directionMobile: null,
	preventSpacing: false,
	fullHeight: false,
});

const computedClass = computed(() => [
	'social-icons',
	`social-icons--${props.direction}`,
]);

const computedStyles = computed(() => ({
	'--m-icon-direction': props.directionMobile || props.direction,
	'--icon-padding-vertical': props.direction === 'column' ? 'var(--space-between-icons)' : '0',
	'--icon-padding-horizontal': props.direction === 'row' ? 'var(--space-between-icons)' : '0',
	...(props.fullHeight && {
		height: '100%',
	}),
	...(props.preventSpacing && {
		'--space-between-icons': '0px',
	}),
	...(props.directionMobile && {
		'--m-icon-padding-vertical': props.directionMobile === 'column' ? 'var(--space-between-icons)' : '0',
		'--m-icon-padding-horizontal': props.directionMobile === 'row' ? 'var(--space-between-icons)' : '0',
	}),
}));
</script>

<style lang="scss" scoped>
/* stylelint-disable custom-property-pattern */
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.social-icons {
	display: flex;
	flex-direction: var(--icon-direction);
	justify-content: var(--icon-spacing, start);
	max-height: 100%;
	padding: 10px;
	padding: var(--gridItemInnerPadding);
	background: var(--gridItemInnerBackground);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		color: var(--icon-color, black);
		transition: color 0.2s ease;

		&:not(:last-child) {
			padding-right: var(--icon-padding-horizontal);
			padding-bottom: var(--icon-padding-vertical);
		}

		&:hover {
			color: var(--icon-color-hover, $color-gray);
		}

		:deep(svg) {
			width: var(--icon-size, 25px);
			height: var(--icon-size, 25px);
			object-fit: contain;
		}
	}
}

@include site-engine-mobile {
	.social-icons {
		flex-direction: var(--m-icon-direction);

		&__link {
			&:not(:last-child) {
				padding-right: var(--m-icon-padding-horizontal, var(--icon-padding-horizontal));
				padding-bottom: var(--m-icon-padding-vertical, var(--icon-padding-vertical));
			}
		}
	}
}
</style>
