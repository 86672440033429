<template>
	<GridVideo
		ref="videoRef"
		v-bind="composedProps"
		:src="composedSource"
		:render-iframe="renderIframe"
		:render-picture="renderPicture"
		@click.once="renderIframe = true"
	/>
</template>

<script>
import {
	onMounted,
	defineComponent,
} from 'vue';

import GridVideo from '@zyro-inc/site-modules/components/elements/video/GridVideo.vue';
import { useGridVideo } from '@zyro-inc/site-modules/components/elements/video/useGridVideo';

export default defineComponent({
	name: 'GridVideoProviderUser',

	components: {
		GridVideo,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	setup(props) {
		const {
			composedProps,
			composedSource,
			init,
			videoRef,
			renderIframe,
			renderPicture,
		} = useGridVideo(props);

		onMounted(() => {
			init();
		});

		return {
			videoRef,
			composedProps,
			composedSource,
			renderIframe,
			renderPicture,
		};
	},
});
</script>
