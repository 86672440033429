// This composable is only used to return rempaped props if there are no poster props.
import {
	computed,
	reactive,
	ref,
	watchEffect,
} from 'vue';

import { extractVideoProps } from '@zyro-inc/site-modules/components/elements/video/extractVideoProps';
import {
	FALSY_PARAM_VALUE,
	TRUTHY_PARAM_VALUE,
	URL_PARAMS,
} from '@zyro-inc/site-modules/components/elements/video/constants';

export const useGridVideo = (props) => {
	const reactiveProps = reactive({
		src: null,
		provider: null,
		width: null,
		height: null,
		webp: null,
		jpg: null,
	});

	const composedProps = reactive({
		src: computed(() => reactiveProps.src ?? props.data.settings.src),
		provider: computed(() => reactiveProps.provider ?? props.data.settings.provider),
		width: computed(() => reactiveProps.width ?? props.data.settings.width),
		height: computed(() => reactiveProps.height ?? props.data.settings.height),
		webp: computed(() => reactiveProps.webp ?? props.data.settings.webp),
		jpg: computed(() => reactiveProps.jpg ?? props.data.settings.jpg),
	});

	watchEffect(() => {
		// This is temporary and should be done by mappers.
		// If there is a .src change or there is no poster (.jpg) make a call to extract new posters.
		// Then remap all extracted props and proxy them to `composedProps`.
		if (props.data.settings.src !== composedProps.src || !props.data.settings.jpg) {
			extractVideoProps(props.data.settings.src).then((extractedProps) => {
				Object.entries(extractedProps).forEach(([key, value]) => {
					reactiveProps[key] = value;
				});
			});
		}
	});

	const videoRef = ref(null);

	const shouldAutoplay = computed(
		() => props.data.settings.src?.includes(`${[URL_PARAMS.AUTOPLAY]}=${TRUTHY_PARAM_VALUE}`) ?? false,
	);

	/**
		 * If .src contains 'autoplay=0' or on mobile, force it to 'autoplay=1' as autoplay is handled
		 * by 'shouldAutoplay' via IntersectionObserver.
		 *
		 * Also, if 'shouldAutoplay', switch mute param to TRUTHY_PARAM_VALUE.
		 */
	const composedSource = computed(() => {
		const muteParameterLabel = URL_PARAMS.MUTE[composedProps.provider];

		return shouldAutoplay.value
			? composedProps.src.replace(
				`${[muteParameterLabel]}=${FALSY_PARAM_VALUE}`,
				`${[muteParameterLabel]}=${TRUTHY_PARAM_VALUE}`,
			)
			: composedProps.src;
	});

	const renderIframe = ref(false);
	const renderPicture = ref(false);

	const init = () => {
		const observer = new IntersectionObserver(([{ isIntersecting }]) => {
			if (isIntersecting) {
				if (shouldAutoplay.value) {
					renderIframe.value = true;
				} else {
					renderPicture.value = true;
				}

				observer.disconnect();
			}
		}, {
			threshold: 0,
		});

		observer.observe(videoRef.value.$el);

		renderIframe.value = true;
	};

	return {
		composedProps,
		composedSource,
		init,
		renderIframe,
		renderPicture,
		videoRef,
	};
};
