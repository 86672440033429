import { SitePages } from '@hostinger/builder-schema-validator';
import {
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

export const isProductPage = (pageType: string) => [
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
].includes(pageType);

export const getIsDynamicProductPageEnabled = (
	{ defaultLocalePages }: {defaultLocalePages?: SitePages},
) => Object.values(defaultLocalePages || {}).some((page) => page?.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT);
