import { SHOPPING_CART_STORAGE_KEY } from '@zyro-inc/site-modules/constants/ecommerce';
import { EcommerceProduct } from '@zyro-inc/site-modules/types';

type ShoppingCartStorageData = {
	expiry: number;
	payload: EcommerceProduct[];
}

// Wrapping the function call in a Promise with setTimeout ensures it is executed as a macrotask.
// This is important because localStorage operations are synchronous but not part of the microtasks queue.
// Using setTimeout guarantees that the localStorage operation is correctly delayed and handled in the macrotask queue.
export const getCartDataFromStorage = (): Promise<EcommerceProduct[]> => new Promise((resolve) => {
	setTimeout(() => {
		const storageValue = window.localStorage.getItem(SHOPPING_CART_STORAGE_KEY);

		if (!storageValue) {
			return resolve([]);
		}

		const data = JSON.parse(storageValue) as ShoppingCartStorageData;

		if (!data.payload.length) {
			return resolve([]);
		}

		if (Date.now() > data.expiry) {
			window.localStorage.removeItem(SHOPPING_CART_STORAGE_KEY);

			return resolve([]);
		}

		return resolve(data.payload);
	}, 0);
});
