import { PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';

export const getHomepageId = ({
	siteData,
	path,
}) => {
	if (!siteData) {
		return null;
	}

	const {
		meta,
		languages,
	} = siteData;

	const { locale } = getPathParams({
		path,
		languageKeys: Object.keys(languages),
		defaultLocale: meta.defaultLocale,
	});

	const { homePageId } = languages[locale];

	return homePageId;
};

export const getIsSlugHomepage = ({
	siteData,
	path,
}) => {
	if (!siteData) {
		return false;
	}

	const {
		meta,
		languages,
	} = siteData;

	const {
		locale,
		slug,
	} = getPathParams({
		path,
		languageKeys: Object.keys(languages),
		defaultLocale: meta.defaultLocale,
	});

	const {
		pages,
		homePageId,
	} = languages[locale];

	return !slug || pages[homePageId].slug === slug;
};

export const getPageIdFromPath = ({
	siteData,
	path,
	products = [],
	isDynamicProductPageEnabled = false,
}) => {
	if (!siteData) {
		return null;
	}

	const {
		meta,
		languages,
	} = siteData;

	const {
		locale,
		slug,
	} = getPathParams({
		path,
		languageKeys: Object.keys(languages),
		defaultLocale: meta.defaultLocale,
	});

	const {
		pages,
		homePageId,
	} = languages[locale];

	if (slug) {
		const productPageId = products?.find((product) => product.seo_settings?.slug === slug)?.id;

		if (productPageId && isDynamicProductPageEnabled) {
			const pageId = Object.entries(pages).find(([_, { type }]) => type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT)?.[0];

			return pageId;
		}

		const pageId = Object.keys(pages).find((id) => {
			if (productPageId) {
				return pages[id].productId === productPageId;
			}

			return pages[id].slug === slug;
		});

		return pageId || homePageId;
	}

	return homePageId;
};
