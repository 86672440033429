<template>
	<div
		:id="id"
		v-qa="'builder-gridelement-gridembed'"
		class="grid-embed"
	>
		<iframe
			v-if="shouldRender"
			ref="gridEmbedIframeRef"
			v-qa="'builder-gridelement-gridembed-iframe'"
			class="grid-embed__iframe"
			:srcdoc="srcdoc"
			title="custom code element"
			v-bind="{ ...(isSandBoxed && { sandbox: `allow-scripts` }) }"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	onBeforeMount,
	onMounted,
	ref,
} from 'vue';

const props = withDefaults(defineProps<{
	srcdoc: string,
	shouldRender: boolean,
	isSandBoxed?: boolean,
	height: number | null,
	heightMobile: number | null,
	id: string,
}>(), {
	srcdoc: '',
	shouldRender: false,
	height: null,
	heightMobile: null,
});

const emit = defineEmits<{
	'iframe-height-updated': [number],
}>();

const observedHeight = ref(0);
const gridEmbedIframeRef = ref<HTMLIFrameElement>();
/**
 * If `observedHeight` becomes bigger (for ex., navigation occurs in Embed), prefer it over original size.
 * Method is used to reduce duplication for desktop/mobile handling.
*/
const getCurrentEmbedHeight = (heightToCompare: number | null) : number => {
	if (!heightToCompare) {
		return observedHeight.value;
	}

	return observedHeight.value > heightToCompare ? observedHeight.value : heightToCompare;
};

const height = computed(() => `${getCurrentEmbedHeight(props.height)}px`);
const heightMobile = computed(() => (props.heightMobile ? `${getCurrentEmbedHeight(props.heightMobile)}px` : 'auto'));

const heightUpdateEventListener = (event: MessageEvent) => {
	if (event.data.action === 'iframeHeightUpdated' && props.id === event.data.id && observedHeight.value !== event.data.height) {
		observedHeight.value = event.data.height;
		emit('iframe-height-updated', observedHeight.value);
	}
};

onMounted(() => {
	window.addEventListener('message', heightUpdateEventListener);
});

onBeforeMount(() => {
	window.removeEventListener('message', heightUpdateEventListener);
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.grid-embed {
	width: 100%;
	height: auto;

	&__iframe {
		width: 100%;
		height: v-bind(height);
		overflow: hidden;
		border: none;
	}
}

@include site-engine-mobile {
	.grid-embed {
		&__iframe {
			height: v-bind(heightMobile);
		}
	}
}
</style>
