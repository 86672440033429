<script setup lang="ts">
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import {
	ECOMERCE_PRODUCTS_FREE_TYPES,
	EcommerceProductMediaType,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	EcommerceProductType,
	EcommerceProductImage,
	EcommerceVariantPrice,
	ImageHoverEffect,
	ImageRatioOption,
} from '@zyro-inc/site-modules/types';

import { formatPrice } from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';
import ProductMediaElement from '@zyro-inc/site-modules/components/ecommerce/ProductMediaElement.vue';
import ProductRibbon from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductRibbon.vue';
import {
	computed,
	ref,
	watch,
} from 'vue';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import {
	DATA_ATTRIBUTE_ANIMATION_ROLE,
	DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { ECOMMERCE_PRODUCT_PLACEHOLDERS_BASE_PATH } from '@zyro-inc/cdn-builder-placeholders/constants';

const CUSTOM_ATTRIBUTES = {
	[DATA_ATTRIBUTE_ANIMATION_ROLE]: DATA_ATTRIBUTE_ANIMATION_ROLE_BLOCK_ELEMENT,
};

const PRODUCT_PLACEHOLDER_IMAGE = `${ECOMMERCE_PRODUCT_PLACEHOLDERS_BASE_PATH}/placeholder.png`;

interface Props {
	id: string | number,
	imageUrl?: string | null;
	secondaryImage?: EcommerceProductImage | null;
	title: string;
	price?: EcommerceVariantPrice | null;
	textAlign?: string;
	isEager?: boolean;
	imageWidth: number;
	imageHeight: number;
	duration: string;
	productType?: EcommerceProductType;
	translations?: Record<string, string>;
	isStoreQuantityTracked?: boolean;
	quantity: number;
	isButtonEnabled?: boolean;
	buttonDisplay?: string;
	buttonText?: string;
	buttonStyle?: Record<string, string>;
	buttonType?: string;
	buttonBorderWidth?: number;
	isPriceRangeShown?: boolean;
	ribbon?: string | null;
	ribbonStyle?: Record<string, string>;
	siteId: string;
	isPurchasable: boolean;
	imageRatio?: ImageRatioOption;
	imageHoverEffect?: ImageHoverEffect;
	imageBorderRadius?: string;
	isMobileView?: boolean;
	isButtonFullWidth?: boolean;
	isCartVisible?: boolean;
	isAddToCartDisabled: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	image: '',
	textAlign: 'left',
	isEager: false,
	productType: EcommerceProductType.PHYSICAL,
	translations: () => ({}),
	isButtonEnabled: false,
	buttonDisplay: '',
	buttonText: '',
	buttonStyle: () => ({}),
	buttonType: 'primary',
	buttonBorderWidth: 0,
	ribbon: '',
	ribbonStyle: () => ({}),
	siteId: '',
	imageRatio: ImageRatioOption.COVER,
	price: null,
	isCartVisible: true,
	isAddToCartDisabled: false,
});

defineEmits(['button-click']);

const animationTimeout = ref();
const isProductHovered = ref(false);

const secondaryImageSrc = computed(() => (props.imageHoverEffect === ImageHoverEffect.SWAP_IMAGE ? props.secondaryImage?.url : null));
const isInStock = computed((): boolean => !props.isStoreQuantityTracked || props.quantity > 0);
const isProductTypeBooking = computed(() => props.productType === EcommerceProductType.BOOKING);
const isFreeTypeProduct = computed(() => ECOMERCE_PRODUCTS_FREE_TYPES.includes(props.productType));
const isFreeTypeProductFree = computed(() => isFreeTypeProduct.value && !props.price?.amount);
const isFreeDigitalProduct = computed(() => props.productType === EcommerceProductType.DIGITAL && !props.price?.amount);

const blockButtonText = computed(() => {
	if (props.buttonText) {
		return props.buttonText;
	}

	if (isProductTypeBooking.value) {
		return props.translations?.bookNow || 'Book now';
	}

	if (isFreeDigitalProduct.value && !props.isCartVisible) {
		return props.translations?.download || 'Download';
	}

	return props.translations?.addToBag || 'Add to bag';
});
const buttonBorderColor = computed((): { normal: string, hover:string } => ({
	normal: props.buttonStyle[`grid-button-${props.buttonType}-border-color`],
	hover: props.buttonStyle[`grid-button-${props.buttonType}-border-color-hover`],
}));
const buttonBackgroundColor = computed((): { normal: string, hover:string } => ({
	normal: props.buttonStyle[`grid-button-${props.buttonType}-background-color`],
	hover: props.buttonStyle[`grid-button-${props.buttonType}-background-color-hover`],
}));
const computedStyles = computed((): Record<string, string> => ({
	...objectToCssVariables(props.buttonStyle),
}));
const objectFit = computed(() => {
	if (props.imageRatio === ImageRatioOption.CONTAIN) {
		return 'contain';
	}

	return 'cover';
});
const isSecodaryImageShown = computed(
	() => props.secondaryImage && isProductHovered.value && props.imageHoverEffect === ImageHoverEffect.SWAP_IMAGE,
);
const imageSrc = computed(() => {
	if (isSecodaryImageShown.value) {
		return props.secondaryImage?.url || '';
	}

	return props.imageUrl || PRODUCT_PLACEHOLDER_IMAGE;
});
const mediaType = computed(() => (isSecodaryImageShown.value ? props.secondaryImage?.type : EcommerceProductMediaType.IMAGE));
const isImageZoomedIn = computed(() => props.imageHoverEffect === ImageHoverEffect.ZOOM && isProductHovered.value);
const imageBorderRadius = computed(() => (props.imageRatio === ImageRatioOption.CONTAIN ? '0%' : props.imageBorderRadius));

const formattedPrice = computed(() => (isFreeTypeProductFree.value
	? props.translations?.free || 'Free'
	: formatPrice({
		amount: props.price?.amount,
		currency: props.price?.currency,
	})));

watch(() => props.imageHoverEffect, (newValue) => {
	if (props.isMobileView) {
		return;
	}

	clearTimeout(animationTimeout.value);

	if (newValue === ImageHoverEffect.SWAP_IMAGE) {
		isProductHovered.value = true;

		animationTimeout.value = setTimeout(() => {
			isProductHovered.value = false;
		}, 1000);
	} else if (newValue === ImageHoverEffect.ZOOM) {
		isProductHovered.value = true;

		animationTimeout.value = setTimeout(() => {
			isProductHovered.value = false;
		}, 1000);
	} else {
		isProductHovered.value = false;
	}
});
</script>

<template>
	<div
		class="product-list-item"
		:class="`product-list-item--${textAlign}`"
		:style="computedStyles"
		v-bind="CUSTOM_ATTRIBUTES"
	>
		<div
			class="product-list-item__content"
			@mouseenter="isProductHovered = isMobileView ? false : true"
			@mouseleave="isProductHovered = false"
		>
			<ProductRibbon
				v-if="ribbon"
				:text="ribbon"
				:ribbon-style="ribbonStyle"
			/>
			<div
				v-qa="'product-list-section-item-image'"
				:class="`
					product-list-item__image-wrapper
					product-list-item__image-wrapper--${imageRatio}
					${imageHoverEffect === ImageHoverEffect.NO_EFFECT ? 'product-list-item__image-wrapper--bg-animation' : ''}
				`"
			>
				<ProductMediaElement
					:src="imageSrc"
					:secondary-image-src="secondaryImageSrc"
					:media-type="mediaType"
					:alt="title"
					:class="`
						product-list-item__image
						product-list-item__image ${isImageZoomedIn ? 'product-list-item__image--zoomed-in' : ''}
						${imageHoverEffect === ImageHoverEffect.ZOOM ? 'product-list-item__image--zoom-animation' : ''}
					`"
					:is-eager="isEager"
					:width="imageWidth"
					:height="imageHeight"
					:site-id="siteId"
					:object-fit="objectFit"
					:image-ratio="imageRatio"
					enable-srcset
					is-video-disabled
					:is-lossless="true"
				/>
			</div>
			<div class="product-list-item__details">
				<h6
					v-qa="'product-list-section-item-title'"
					class="product-list-item__title"
				>
					{{ title }}
				</h6>
				<div
					v-if="price"
					v-qa="'product-list-section-item-price'"
					class="product-list-item__price-wrapper"
					:class="{ 'product-list-item__price-wrapper--with-booking': productType === EcommerceProductType.BOOKING }"
				>
					<template v-if="isInStock">
						<p>
							<span
								v-if="isPriceRangeShown && !price.sale_amount"
								class="product-list-item__price-range"
							>
								{{ translations.from }}
							</span>
							<span
								:class="{
									'product-list-item__price': price.sale_amount,
									'product-list-item__booking-price': productType === EcommerceProductType.BOOKING
								}"
							>
								{{ formattedPrice }}
							</span>
						</p>
						<p
							v-if="price.sale_amount"
							class="product-list-item__price-content"
						>
							<template v-if="isPriceRangeShown">
								{{ translations.from }}
							</template>
							{{ formatPrice({
								amount: price.sale_amount,
								currency: price.currency
							}) }}
						</p>
						<p
							v-if="productType === EcommerceProductType.BOOKING"
							class="product-list-item__duration"
						>
							{{ duration }}
						</p>
					</template>
					<p
						v-else
						class="product-list-item__text"
					>
						{{ translations.soldOut }}
					</p>
				</div>
			</div>
		</div>
		<div
			v-if="isButtonEnabled && isPurchasable"
			class="product-list-item__button-wrapper"
			:class="{
				'product-list-item__button-wrapper--hidden': !isInStock,
				'product-list-item__button-wrapper--with-hover': buttonDisplay === 'hover'
			}"
			@click.prevent.stop
		>
			<GridButton
				v-qa="`productlistsection-btn-addtobag`"
				:type="buttonType"
				:content="blockButtonText"
				class="product-list-item__button"
				:class="[
					`product-list-item__button--${buttonType}`,
					{ 'product-list-item__button--full-width': isButtonFullWidth }
				]"
				tag-name="button"
				:disabled="isAddToCartDisabled"
				:border-width="buttonBorderWidth"
				:border-color="buttonBorderColor.normal"
				:border-color-hover="buttonBorderColor.hover"
				:background-color="buttonBackgroundColor.normal"
				:background-color-hover="buttonBackgroundColor.hover"
				@click="$emit('button-click')"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@import "@zyro-inc/site-modules/components/blocks/ecommerce/-partials/shared";
@include font-style("h6", "h6", ".product-list-item");
@include font-style("body", "p", ".product-list-item");

.product-list-item {
	$this: &;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	cursor: pointer;

	&--center {
		align-items: center;
		text-align: center;

		#{$this}__price-wrapper, #{$this}__button-wrapper {
			justify-content: center;
		}
	}

	&--outlined {
		border: 1px solid $color-gray-border;

		#{$this}__details {
			padding: 0 10px 10px;
		}

		#{$this}__button-wrapper {
			padding: 8px 10px 10px;
			margin-top: -10px;
		}

		#{$this}__image-wrapper--contain {
			border: none;
			border-bottom: 1px solid $color-gray-border;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	&__image-wrapper {
		position: relative;
		display: flex;
		width: 100%;
		overflow: hidden;
		transition: height 0.2s ease-in;
		height: 0;
		border-radius: v-bind(imageBorderRadius);

		&--portrait {
			// aspect ratio: 4 / 5;
			// 5/4 * 100
			padding-bottom: 125%;

		}

		&--landscape {
			// aspect ratio: 16 / 9
			// 9/16 * 100
			padding-bottom: 56.25%;
		}

		&--cover,
		&--contain {
			// aspect ratio: 1;
			// 1 * 100
			padding-bottom: 100%;
		}

		&--contain {
			border: 1px solid $color-gray-border;

			#{$this} {
				&__image {
					// Fixed chrome bug with image border
					width: calc(100% + 2px);
					height: calc(100% + 2px);
					margin: -1px;

					// Center absolute position
					left: 50%;
					transform: scale(1) translateX(-50%);

					&.product-list-item__image--zoomed-in {
						// +1px because margin is -1px
						transform: scale(1.2) translateX(calc(-50% / 1.2 + 1px));
					}
				}
			}
		}
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: v-bind(objectFit);
		object-position: center;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		&--zoom-animation {
			transform: scale(1);
			transition: 0.1s transform;
		}

		&--zoomed-in {
			transform: scale(1.2);
		}
	}

	&__details {
		display: flex;
		flex-direction: column;
	}

	&__title {
		word-break: break-word;
		margin: 20px 0 8px;
	}

	&__price-wrapper {
		display: flex;
	}

	&__price-content {
		display: flex;
		margin-right: 8px;
	}

	&__price {
		margin-right: 8px;
		opacity: 0.4;

		&#{&} {
			text-decoration: line-through;
		}
	}

	&__price-range{
		margin-right: 4px;
	}

	&__booking-price {
		margin-right: 8px;
	}

	&__text {
		&#{&} {
			text-transform: uppercase;
		}

		opacity: 0.6;
	}

	&__duration {
		&::before {
			margin-right: 8px;
			content: "|";
		}
	}

	&__button-wrapper {
		display: flex;
		width: 100%;
		padding-top: 8px;
		transition: opacity 0.3s ease-in;

		&--hidden {
			pointer-events: none;
			visibility: hidden;
		}

		&--with-hover {
			opacity: 0;
		}
	}

	&__button {
		position: relative;
		display: flex;
		align-items: center;
		height: var(--button-height);
		padding-left: 16px;
		padding-right: 16px;

		&#{&} {
			white-space: break-spaces;
			word-break: break-all;
		}

		&--primary {
			margin: calc(-1 * var(--grid-button-primary-border-null, var(--grid-button-primary-border-width, 0px)));
			background-color: var(--grid-button-primary-background-color);

			&:focus,
			&:hover {
				margin: calc(-1 * var(--grid-button-primary-border-null-hover, var(--grid-button-primary-border-width-hover, 0px)));
				background-color: var(--grid-button-primary-background-color-hover, var(--grid-button-primary-background-color));
			}
		}

		&--secondary {
			margin: calc(-1 * var(--grid-button-secondary-border-null, var(--grid-button-secondary-border-width, 0px)));
			background-color: var(--grid-button-secondary-background-color);

			&:focus,
			&:hover {
				margin:
					calc(
						-1 * var(
							--grid-button-secondary-border-null-hover,
							var(--grid-button-secondary-border-width-hover, 0px)
						)
					);
				background-color:
					var(
						--grid-button-secondary-background-color-hover,
						var(--grid-button-secondary-background-color)
					);
			}
		}

		&--full-width {
			width: 100%;
			text-align: center;
			align-items: center;
			justify-content: center;
		}
	}
}

@include site-engine-mobile {
	.product-list-item {
		&__button-wrapper {
			&--with-hover {
				opacity: 1;
			}
		}

		&__booking-price {
			margin-right: 8px;
		}

		&__price-wrapper {
			flex-wrap: wrap;
		}
	}
}

@media screen and (min-width: $media-mobile) {
	.product-list-item {
		$this: &;

		&:hover,
		&:focus {
			#{$this}__image-wrapper--bg-animation {
				&::after {
					background-color: rgba($color-dark, 0.2);
				}
			}

			#{$this}__button-wrapper {
				opacity: 1;
			}
		}
	}
}
</style>
