import { BLOCK_STICKY_BAR_ID } from '@zyro-inc/site-modules/constants/siteModulesConstants';

export const getAllSiteElements = ({ languages }) => Object.values(languages).flatMap((language) => Object.values(language.elements));
export const getStickyBarItems = ({ languages }) => Object.values(languages).flatMap(
	(language) => language.blocks[BLOCK_STICKY_BAR_ID]?.items ?? [],
);

export const sortLanguagesArray = (languages) => {
	if (!languages.toSorted) return languages;

	const {
		withPositions,
		reorderedLanguages,
	} = languages.reduce((acc, lang) => {
		if (Number.isInteger(lang.orderPosition) && lang.orderPosition >= 0) {
			acc.withPositions.push(lang);
		} else {
			acc.reorderedLanguages.push(lang);
		}

		return acc;
	}, {
		withPositions: [],
		reorderedLanguages: [],
	});

	withPositions.toSorted((a, b) => a.orderPosition - b.orderPosition).forEach((language) => {
		reorderedLanguages.splice(language.orderPosition, 0, language);
	});

	return reorderedLanguages;
};
