import { ref } from 'vue';

const openEcommerceModalName = ref('');
const productPreviewData = ref({});
const quickPreviewProductId = ref('');

export const useEcommerceModal = () => {
	const openEcommerceModal = (name) => {
		openEcommerceModalName.value = name;
	};

	const closeEcommerceModal = () => {
		openEcommerceModalName.value = null;
	};

	const setProductPreviewData = (blockData, productId) => {
		productPreviewData.value = blockData;
		quickPreviewProductId.value = productId;
	};

	return {
		openEcommerceModal,
		closeEcommerceModal,
		openEcommerceModalName,
		productPreviewData,
		quickPreviewProductId,
		setProductPreviewData,
	};
};
