import {
	EcommerceCurrency,
	EcommerceProduct,
	EcommerceProductSeoSettings,
	EcommerceProductVariantQuantity,
} from '@zyro-inc/site-modules/types';
import { formatPrice } from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';

type ProductSchema = {
	'@context': string;
	'@type': string;
	name: string;
	image: string;
	description: string;
	offers?: {
		'@type'?: string;
		url?: string;
		priceCurrency?: string;
		price?: string;
		lowPrice?: string;
		highPrice?: string;
		availability?: string;
	};
};
type PriceData = {
	lowestAmount: number;
	highestAmount: number;
	currency: EcommerceCurrency;
	productTitle: string;
}

type SeoData = {
	id: string,
	title: string,
	name: string,
	slug: string,
	type: string,
	blocks: string[],
	productId: string,
	meta: EcommerceProductSeoSettings,
}

export const getProductJsonLd = ({
	seoData,
	priceData,
	pageSlug,
	domain,
}: {
	seoData: SeoData,
	priceData: PriceData,
	pageSlug: string,
	domain: string,
}): ProductSchema => {
	const productSchema: ProductSchema = {
		'@context': 'https://schema.org/',
		'@type': 'Product',
		name: priceData?.productTitle,
		image: seoData.meta.ogImagePath || '',
		description: seoData.meta.description || '',
		offers: {},
	};

	if (!priceData) {
		return productSchema;
	}

	const productHasVariants = priceData.lowestAmount !== priceData.highestAmount;

	if (productHasVariants) {
		productSchema.offers = {
			'@type': 'AggregateOffer',
			url: `https://${domain}/${pageSlug}`,
			priceCurrency: priceData.currency.code,
			lowPrice: formatPrice({
				amount: priceData.lowestAmount,
				currency: priceData.currency,
				isPriceDisplayedWithCurrency: false,
			}),
			highPrice: formatPrice({
				amount: priceData.highestAmount,
				currency: priceData.currency,
				isPriceDisplayedWithCurrency: false,
			}),
		};
	} else {
		const priceAmount = formatPrice({
			amount: priceData.lowestAmount,
			currency: priceData.currency,
			isPriceDisplayedWithCurrency: false,
		});

		productSchema.offers = {
			'@type': 'Offer',
			url: `https://${domain}/${pageSlug}`,
			priceCurrency: priceData.currency.code,
			price: priceAmount,
			availability: 'https://schema.org/InStock',
		};
	}

	return productSchema;
};

export const updateProductJsonLd = (product: EcommerceProduct, variantsQuantity: Array<EcommerceProductVariantQuantity>) => {
	const scripts = document.head.getElementsByTagName('script');
	// eslint-disable-next-line unicorn/prefer-spread
	const script: HTMLScriptElement | undefined = Array.from(scripts).find(
		(s): s is HTMLScriptElement => s.type === 'application/ld+json' && !!s.textContent?.includes('https://schema.org/'),
	);

	if (!script || !script.textContent) {
		return;
	}

	const schemaJsonLd = JSON.parse(script.textContent);
	const isProductWithVariants = product.variants.length > 1;
	const isProductInStock = product.variants[0].manage_inventory ? !!variantsQuantity[0].inventory_quantity : true;

	// Availability is already set on astro build
	if (!isProductWithVariants && isProductInStock) {
		return;
	}

	// Availability is not set for AggregateOffer which is meant for products with variants https://schema.org/AggregateOffer.
	// Since we dont handle variants yet, do not set availability for them.
	if (isProductWithVariants) {
		delete schemaJsonLd.offers.availability;
	} else {
		schemaJsonLd.offers.availability = 'https://schema.org/OutOfStock';
	}

	script.textContent = JSON.stringify(schemaJsonLd);
	document.head.removeChild(script);

	const newScript = document.createElement('script');

	newScript.type = 'application/ld+json';
	newScript.textContent = JSON.stringify(schemaJsonLd);
	document.head.appendChild(newScript);
};
