import { GRID_EMBED_STYLE_RESET } from '@zyro-inc/site-modules/components/elements/embed/constants';

export const constructSrcdoc = (content: string, id: string): string => {
	const temporaryElement = document.createElement('div');

	temporaryElement.innerHTML = content;

	// TS doesn't allow using spread on HTMLCollection, even though it works.
	// Tried adding `"downlevelIteration": true` to tsconfig, but it didn't help. So ignoring the error as it works.
	// @ts-ignore
	const extractStylesScripts = [...temporaryElement.children].reduce((acc, curr) => {
		if (curr.tagName === 'STYLE') {
			return {
				...acc,
				styles: `${acc.styles} ${curr.outerHTML}`,
			};
		}

		if (curr.tagName === 'SCRIPT') {
			return {
				...acc,
				scripts: `${acc.scripts} ${curr.outerHTML}`,
			};
		}

		return {
			...acc,
			html: `${acc.html} ${curr.outerHTML}`,
		};
	}, {
		styles: GRID_EMBED_STYLE_RESET,
		scripts: '',
		html: '',
	});

	return `<!DOCTYPE html>
	<head>${extractStylesScripts.styles}</head>
	<body>${extractStylesScripts.html}${extractStylesScripts.scripts}</body>
	<script>
		const throttle = (function_, time) => {
			let wait = false;

			return function executedFunction() {
				if (!wait) {
					Reflect.apply(function_, this, arguments);
					wait = true;
					setTimeout(() => {
						wait = false;
					}, time);
				}
			};
		};

		const heightObserver = new ResizeObserver(throttle(([{ contentRect }]) => {
			window.parent.postMessage({ action: 'iframeHeightUpdated', height: contentRect.height, id: '${id}' }, '*');
		}), 1000);

		heightObserver.observe(document.body);
	</script>
</html>`;
};
