<script setup lang="ts">
import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import { useGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridButton';
import { useEcommerceGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridEcommerceButton';
import { getIsInIframe } from '@zyro-inc/site-modules/utils/getIsInIframe';

import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import {
	EcommerceProduct,
	EcommerceProductType,
} from '@zyro-inc/site-modules/types';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

import {
	computed,
	ref,
	onMounted,
} from 'vue';
import { getProductByIdOrSlug } from '@zyro-inc/site-modules/api/StoreApi';
import { SiteElement } from '@hostinger/builder-schema-validator';

interface Props {
	data: SiteElement,
	isCartVisible?: boolean,
	mobileElementWidth?: number,
	mobileElementHeight?: number,
	isInPreviewMode?: boolean
}

const props = defineProps<Props>();

const {
	getButtonHref,
	ecommerceStoreId,
} = useSiteGlobal();

const {
	canAddToCart,
	isShoppingCartOpen,
	shoppingCartItems,
	setShoppingCartOpen,
	setShoppingCartItems,
	setSelectedBookingProduct,
	updateVariantsQuantity,
} = useEcommerceGlobal();
const { initiateCheckout } = useEcommerce();
const { openEcommerceModal } = useEcommerceModal();
const {
	content,
	type,
	fontSizeMobile,
	fontSizeDesktop,
	fontFamily,
	fontWeight,
	borderRadius,
	backgroundColor,
	fontColor,
	borderColor,
	borderWidth,
	backgroundColorHover,
	fontColorHover,
	borderColorHover,
} = useGridButton(props, {
	href: computed(() => getButtonHref({
		isFormButton: props.data.settings?.isFormButton,
		linkedPageId: props.data.linkedPageId,
		linkType: props.data.linkType,
		href: props.data.href,
	})),
});

const {
	productId,
	productVariantId,
} = useEcommerceGridButton(props);
const product = ref<EcommerceProduct>({} as EcommerceProduct);
const isLoading = ref(false);

const isAddToCartDisabled = computed(() => !canAddToCart(product.value, productVariantId.value));

const manageCartOpenState = () => {
	if (isShoppingCartOpen.value) {
		return;
	}

	setShoppingCartOpen(true);
};

const handleClick = async () => {
	if (product.value?.type?.value === EcommerceProductType.BOOKING) {
		setSelectedBookingProduct(product.value);
		openEcommerceModal('EcommerceBookingEventSelect');

		return;
	}

	if (getIsInIframe() || props.isInPreviewMode) {
		openEcommerceModal('EcommerceMessageButtonDisabled');

		return;
	}

	const variant = product.value?.variants.find((item) => item.id === productVariantId.value);
	const productToAdd = {
		...product.value,
		variants: [variant],
	};

	if (props.isCartVisible) {
		setShoppingCartItems([
			...shoppingCartItems.value,
			productToAdd,
		]);

		manageCartOpenState();
	} else {
		isLoading.value = true;

		await initiateCheckout([productToAdd]).then(() => {
			isLoading.value = false;
		});
	}
};

onMounted(async () => {
	if (productId.value === -1) {
		return;
	}

	product.value = await getProductByIdOrSlug({
		storeId: ecommerceStoreId.value,
		productId: productId.value,
	});

	updateVariantsQuantity([product.value]);
});
</script>

<template>
	<GridButton
		v-qa="'button-ecommerce-checkout'"
		tag-name="button"
		:content="content"
		:type="type"
		:is-disabled="isAddToCartDisabled"
		:is-loading="isLoading"
		:font-size-mobile="fontSizeMobile"
		:font-size-desktop="fontSizeDesktop"
		:font-family="fontFamily"
		:font-weight="fontWeight"
		:border-radius="borderRadius"
		:border-width="borderWidth"
		:background-color="backgroundColor"
		:font-color="fontColor"
		:border-color="borderColor"
		:background-color-hover="backgroundColorHover"
		:font-color-hover="fontColorHover"
		:border-color-hover="borderColorHover"
		:mobile-element-width="mobileElementWidth"
		:mobile-element-height="mobileElementHeight"
		@click="handleClick"
	/>
</template>
