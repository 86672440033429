export const getBatchedArrays = <T>(array: T[], batchSize: number): T[][] => {
	const batchedArrays: T[][] = [];
	const arrayLength = array.length;

	let i = 0;

	while (i < arrayLength) {
		batchedArrays.push(array.slice(i, i + batchSize));
		i += batchSize;
	}

	return batchedArrays;
};
